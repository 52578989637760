






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import { displayItemsWithName, displayText } from "@/utils/marketItemDisplay";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(setting) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "startTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý CPQC",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              campaignName: {
                attrs: {
                  label: "Tên chiến dịch",
                },
                rules: {
                  match: {},
                },
              },
              startTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              value: {
                type: "integer",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (>=)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (<=)",
                    },
                  },
                },
              },
              adsAgencyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Agencies",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencies", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              adsAgencyAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản quảng cáo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencyAccounts", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT User",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              productGroupId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              adsCurrencyName: {
                attrs: {
                  label: "Đơn vị tiền tệ",
                },
                rules: {
                  equal_to: {},
                },
              },
              description: {
                attrs: {
                  label: "Mô tả",
                },
                rules: {
                  match: {},
                },
              },
              shopId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("shops", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            campaignName: {
              text: "Tên chiến dịch",
              sortable: true,
            },
            reachedCount: {
              text: "Người tiếp cận",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            displayedCount: {
              text: "Lượt hiển thị",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            frequency: {
              text: "Tần suất",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            accountType: {
              text: "Loại tài khoản",
              sortable: true,
            },
            value: {
              text: "Số tiền đã chi tiêu",
              sortable: true,
              options: {
                preTransform(value, item) {
                  return (value || 0) * (item.adsCurrencyExchangeRate || 0);
                },
                filter: "number3",
              },
            },
            fee: {
              text: "Phí",
              sortable: true,
              options: {
                preTransform(value, item) {
                  return (value || 0) * (item.adsCurrencyExchangeRate || 0);
                },
                filter: "number3",
              },
            },
            tax: {
              text: "Thuế",
              sortable: true,
              options: {
                preTransform(value, item) {
                  return (value || 0) * (item.adsCurrencyExchangeRate || 0);
                },
                filter: "number3",
              },
            },
            total: {
              text: "CP MKT phải chịu",
              sortable: true,
              options: {
                preTransform(_, item) {
                  return (item.value + (item.fee || 0) + (item.tax || 0)) * (item.adsCurrencyExchangeRate || 0);
                },
                filter: "number3",
              },
            },
            cdpb: {
              text: "Cài đặt phân bổ",
              sortable: true,
              options: {
                textMaxWidth: "100px",
              },
            },
            startTime: {
              text: "Bắt đầu báo cáo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            endTime: {
              text: "Kết thúc báo cáo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            code: {
              text: "Mã",
              sortable: true,
            },
            rd: {
              text: "RD",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            adsCurrency: {
              text: "Đơn vị tiền tệ",
              sortable: true,
              options: {
                label: true,
                subProp: "adsCurrency.name",
                sortBy: "adsCurrencyId",
              },
            },
            adsCurrencyExchangeRate: {
              text: "Tỉ giá",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            adsAgency: {
              text: "Agency",
              sortable: true,
              options: {
                label: true,
                subProp: "adsAgency.name",
                sortBy: "adsAgencyId",
              },
            },
            adsAgencyAccount: {
              text: "TKQC",
              sortable: true,
              options: {
                label: true,
                subProp: "adsAgencyAccount.name",
                sortBy: "adsAgencyAccountId",
              },
            },
            mktUser: {
              text: "MKT User",
              sortable: true,
              options: {
                label: true,
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            shop: {
              text: "Shop",
              sortable: true,
              options: {
                label: true,
                preTransform(value) {
                  return displayText(value, "name");
                },
              },
            },
            productGroup: {
              text: "Nhóm sản phẩm",
              sortable: true,
              options: {
                label: true,
                subProp: "productGroup.sku",
                sortBy: "productGroupId",
              },
            },
            description: {
              text: "Mô tả",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("adsCosts", "import", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const lines = [`Cập nhật thành công: ${result.updatedIndexes.length}`, "", `Các hàng bị lỗi ${result.errorIndexes.join(",")}`];
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("adsCosts", `@/export`, findAllOptions);
                },
              },
            },
            delete: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xóa",
                icon: "mdi-trash",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Xác nhận xoá tất cả các CPQC theo bộ lọc hiện tại?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      let findAllOptions = dataTable.getFindAllOptions();
                      if (dataTable.model.value.length) {
                        findAllOptions = {
                          payload: JSON.stringify({
                            filters: [
                              {
                                key: "_id",
                                operator: "in_id",
                                value: dataTable.model.value.map((item) => item._id),
                              },
                            ],
                            limit: -1,
                          }),
                        };
                      }
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call("adsCosts", "deleteAll", findAllOptions, {});
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "campaignName",
                    },
                  },
                },
                ext: {
                  makeAttrs(item) {
                    const attrs = {
                      xDisabled: new DataContainer(setting.adsCostLockTime && item.startTime < setting.adsCostLockTime),
                    };
                    return attrs;
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {
                ext: {
                  makeAttrs(item) {
                    const attrs = {
                      xDisabled: new DataContainer(setting.adsCostLockTime && item.startTime < setting.adsCostLockTime),
                    };
                    return attrs;
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsCosts", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("adsCosts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsCosts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsCosts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              campaignName: {
                attrs: {
                  label: "Tên chiến dịch",
                },
              },
              reachedCount: {
                attrs: {
                  label: "Người tiếp cận",
                },
              },
              displayedCount: {
                attrs: {
                  label: "Lượt hiển thị",
                },
              },
              frequency: {
                attrs: {
                  label: "Tần suất",
                },
              },
              accountType: {
                attrs: {
                  label: "Loại tài khoản",
                },
              },
              value: {
                type: "integer",
                attrs: {
                  label: "Số tiền đã chi tiêu",
                },
              },
              cdpb: {
                attrs: {
                  label: "Cài đặt phân bổ",
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Bắt đầu báo cáo",
                },
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Kết thúc báo cáo",
                },
              },
              adsAgencyAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản quảng cáo",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencyAccounts", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              productGroupId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              shopId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("shops", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["description", "campaignName", "reachedCount", "displayedCount", "frequency", "accountType", "value", "cdpb", "value", "startTime", "endTime", "adsAgencyAccountId", "mktUserId", "productGroupId", "shopId"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["description", "campaignName", "reachedCount", "displayedCount", "frequency", "accountType", "value", "cdpb", "value", "startTime", "endTime", "adsAgencyAccountId", "mktUserId", "productGroupId", "shopId"],
              },
            },
            delete: {
              confirmDisplayField: "campaignName",
            },
          },
        },
      };
    },
  },
  async created() {
    const setting = await coreApiClient.call("setting", "get", undefined, undefined);
    this.tableOptions = this.makeTableOptions(setting);
  },
});
