export function displayText(item, key) {
    if (!item) {
        return ""
    }
    if (item.market) {
        return `[${item.market.code}] ${item[key]}`
    } else if (item.country) {
        return `[${item.country.code}] ${item[key]}`
    }
    return item[key]
}

export function undisplayText(text) {
    if (!text) {
        return ""
    }
    const arr = text.split("]")
    if (arr.length === 1) {
        return text
    }
    return arr.slice(1).join("]").trim()
}

export function displayItem(item: any, key, disabled, text?): any {
    if (!item || disabled) {
        return ""
    }
    if (!item.__decorated) {
        item[key] = displayText(item, key)
    }
    item.__decorated = true
    if (text) {
        return item[key]
    }
    return item
}

export function displayItems(items: any[], key, disabled?): any[] {
    if (disabled) {
        return items
    }
    return items.map(item => displayItem(item, key, disabled))
}

export function displayItemWithSku(item: any, disabled?, text?): any {
    return displayItem(item, "sku", disabled, text)
}

export function displayItemWithName(item: any[], disabled?, text?): any {
    return displayItem(item, "name", disabled, text)
}

export function displayItemsWithSku(items: any[], disabled?): any[] {
    return displayItems(items, "sku", disabled)
}

export function displayItemsWithName(items: any[], disabled?): any[] {
    return displayItems(items, "name", disabled)
}
